.landing_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-evenly;
}
html {
	scroll-behavior: smooth;
}
.landing_min {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 80vh;
	justify-content: space-between;
}

.landing_title {
	color: #e5e5e5;
	font-size: 2.26rem;
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: "Roboto";
	margin-top: 3.125rem; /* 50px */
}
.hello_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.landing_title h2 {
	font-size: 2.5rem;
	color: #6acfc7;
	margin-right: 0.3125rem; /* 5px */
}
.landing_html {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-transform: capitalize;
	line-height: 1.5625rem; /* 25px */
}
.landing_html p {
	margin: 0;
}
.par_element1,
.par_element2 {
	color: #fff;
	font-size: 0.9rem;
	width: 50%;
}
.par_element2 {
	display: flex;
	justify-content: flex-end;
}
.par_description {
	color: #fff;
	width: 50%;
	font-size: 1.3rem;
	font-weight: 50;
	display: flex;
	justify-content: center;
	text-align: center;
}

/* More CSS here ... */

@media (max-width: 81.25rem) {
	/* 1300px */
	.landing_min {
		min-height: 70vh;
	}
}
@media (max-width: 53.125rem) {
	/* 850px */
	.landing_title {
		font-size: 2rem;
		margin-top: 1.25rem; /* 20px */
	}
}

@media (max-width: 40.625rem) {
	/* 650px */
	.landing_title h2 {
		font-size: 2rem;
	}
	.landing_title {
		font-size: 1.5rem;
	}
}

@media (max-width: 31.25rem) {
	/* 500px */
	.landing_title h2 {
		font-size: 1.6rem;
	}
	.landing_title {
		font-size: 1.3rem;
	}
}

@media (max-width: 26.25rem) {
	/* 420px */
	.landing_title h2 {
		font-size: 1.3rem;
	}
	.landing_title {
		font-size: 1rem;
	}
	.landing_animation {
		height: 1.875rem; /* 30px */
	}
	.landing_html p {
		font-size: 1rem;
		width: 90%;
	}

	.landing_contact {
		width: 11em;
		height: 2.5em;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.8rem;
	}
}
section img {
	max-width: 100%;
	display: block;
}
.image-container {
	position: relative;
	display: inline-block;
	width: fit-content;
}
.overlay-image {
	display: block;
	max-width: 100%;
	height: auto;
	background-color: transparent;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	mix-blend-mode: normal;
}
