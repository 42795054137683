.contactForm_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-items: center;
	margin-bottom: 50px;
}

.contactForm_title h1 {
	font-weight: 200;
}

.contactForm_title {
	display: flex;
	justify-content: center;
	width: 100%;
	font-size: 1.8rem;
	text-transform: uppercase;
	animation: 1.5s fadeInUp;
}

.contactForm_title h1 {
	color: #ffffff;
}

.form_container {
	display: flex;
	flex-direction: column;
	width: 90%;
	align-items: center;
	margin-top: 50px;
}

.contact_media {
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	border-top: 1px solid black;
	background-color: transparent;
}

.contact_media .contact_media_div {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #ffffff;
}

.contact_media p {
	background-color: transparent;
	color: black;
	font-size: 0.9rem;
}

.contact_media h2 {
	width: 100%;
	display: flex;
	background-color: #00509d;
	color: #6acfc7;
}

.contact_icons {
	background-color: #6acfc7;
	display: flex;
	align-items: center;
}

.form_container form {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 30px;
	background-color: #ffffff;
	border-radius: 15px;
}

.form_container form h2 {
	background-color: #ffffff;
	color: black;
}

.form_container form input,
.form_container form textarea {
	padding: 10px;
	margin-bottom: 30px;
	border: 2px solid black;
	font-family: "Rubik";
	background-color: rgb(237, 252, 255);
	color: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}

.form_container form textarea {
	height: 200px;
}

::placeholder {
	color: black;
}

.form_container .form .submit_button {
	width: 40%;
	margin: auto;
	color: #0c1a1a;
	padding: 13px;
	background-color: #0c1a1a;
	border-radius: 50px;
	border: transparent;
	font-size: 1.2rem;
	font-weight: 600;
	text-decoration: underline;
	background: linear-gradient(#6acfc7, #6acfc7 50%, #0c1a1a 50%, #0c1a1a);
	background-size: 100% 200%;
	transition: 0.4s;
}
.submit_button p {
	background-color: transparent;
	margin: 0;
}

.form_container .form button:hover {
	background-position: 100% 100%;
	color: #ffffff;
}

.sent_email {
	display: flex;
	justify-content: center;
	background-color: green;
	border-radius: 50px;
	border: transparent;
	color: #ffffff;
	font-size: 1.2rem;
	font-weight: 600;
	width: 40%;
	margin: auto;
	padding: 13px;
}
.sent_email p {
	display: flex;
	justify-content: space-evenly;
	width: 60%;
	margin: 0;
	background-color: transparent;
}

@media (max-width: 750px) {
	.contactForm_container {
		width: 80%;
	}
	.contactForm_title {
		font-size: 1rem;
	}
}

@media (max-width: 420px) {
	.form_container .form .submit_button {
		width: 70%;
	}
	.sent_email {
		width: 70%;
	}
}
