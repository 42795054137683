.work_main_container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.projects_main_container p {
	font-size: 1rem;
	font-family: "Rubik";
}

.projects_container {
	color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	margin-bottom: 50px;
}
.projects {
	color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.singleProject {
	display: flex;
	flex-direction: column;
	width: 70%;
	align-content: center;
	margin-bottom: 50px;
}
.projects_title {
	font-size: 1.5rem;
	margin-bottom: 20px;

	animation: 1.5s fadeInUp;
}
.singleProject a {
	animation: 1.5s fadeInUp;
}
@keyframes fadeInUp {
	0% {
		transform: translateY(80%);
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

.img_container {
	display: flex;
	width: 80%;

	justify-content: center;
}
.img_container a {
	text-decoration: none;
	width: 100%;
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 850px) {
	.singleProject {
		width: 90%;
	}
}
@media (max-width: 420px) {
	.projects_main_container .projects_title {
		width: 100%;
		font-size: 1rem;
		text-align: center;
	}
	.work_main_container .img_container img {
		height: 250px;
	}
}
