/* Reset browser defaults */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
	background-color: #0c1a1a;
}

/* Apply basic styling and dark theme */
body {
	background-color: #1c1c1c;
	color: #f5f5f5;
	line-height: 1.6;
}

a {
	color: #0053a0;
	text-decoration: none;
}

a:hover {
	color: #00a8ff;
}

/* Typography */
h1,
h2,
p {
	margin-bottom: 1.25rem;
}

header h1 {
	font-size: 2.5rem;
}

header p,
footer p {
	font-size: 1.1rem;
}

section h2 {
	font-size: 2rem;
	margin-bottom: 1.875rem;
}

/* Layout */
header,
footer {
	padding: 1.25rem;
	text-align: center;
}

section {
	padding: 2.5rem;
}

.App {
	display: flex;
	flex-direction: row;
	height: 100%;
}

/* Media */
section img {
	max-width: 100%;
	display: block;
	margin: 1.25rem 0;
}
